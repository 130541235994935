import React, { useEffect, useState } from "react";
import api from "../../../../../services/api";
import Sacops from "../../../../../components/jsons/routesTranparency/routesSacopAndLicitacoes";
import NavMenu from "../../../../../components/public/others/Navbar";
import NavMenuCM from "../../../../../components/public/others/NavbarCM";
import Footer from "../../../../../components/public/others/Footer";
import ListSacop from "../../../../../components/public/transparency/sacop/List/padrao/ListAll";
import Loading from "../../../../../components/public/others/Loading";
import Search from "../../../../../components/public/others/ordinances/Utils";
import { TipoPortal } from "../../../../../services/variables";
import SacopListTypeAndSubtype from "../../../../../components/public/transparency/sacop/subTypes/sacopListTypeAndSubtype";
import ShowDocuments from "../../../../../components/public/others/ordinances/ShowResult";
import ListAndFiltersType from "../../../../../components/public/transparency/sacop/subTypes/ListAndFiltersType";

const formatString = (data, keys) => {
  return data.map((item) => {
    const formattedItem = {};
    Object.keys(item).forEach((key) => {
      let value = item[key];
      if (key.toUpperCase().includes("DATA")) {
        value = dateRender(value);
      }
      if (key.toUpperCase().includes("CNPJ")) {
        value = value
          ? value.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
            )
          : "";
      }
      if (typeof value !== "string" && key.toUpperCase().includes("VALOR")) {
        value = moneyFormat(value);
      } else if (typeof value !== "string") {
        value = value ? value.toString() : "";
      }
      formattedItem[key] = value;
    });
    return formattedItem;
  });
};

const moneyFormat = (money) => {
  if (money === "") {
    return money;
  } else {
    return money.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      currency: "BRL",
    });
  }
};

function dateRender(dataString) {
  const data = new Date(dataString);
  data.setMinutes(data.getTimezoneOffset());
  return data.toLocaleDateString("pt-BR");
}

const ListCardSacopAndFilters = ({ name: SacopFilter }) => {
  const [listResult, setListResult] = useState();
  const [type, setType] = useState();
  const [nameTypeDeclaracao, setNameTypeDeclaracao] = useState();
  const [route, setRoute] = useState();
  const [removeLoading, setRemoveLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/sacop/list/all/${type}`);
        if (!res.data.err) {
          const result = res.data.res;
          setListResult(
            result.length !== 0
              ? formatString(result, Object.keys(result[0]))
              : result
          );
        }
      } catch (err) {
        // Handle error

        setListResult([]);
        setRemoveLoading(true);
      }
    };

    fetchData();
  }, [type]);

  useEffect(() => {
    for (const prop in Sacops) {
      if (Sacops[prop] && Sacops[prop].TITLE === SacopFilter) {
        setType(Sacops[prop].SELECT);
        setRoute(Sacops[prop].ROUTE);
        if (Sacops[prop]["SELECT-TYPE-DECLARACAO"]) {
          setNameTypeDeclaracao(Sacops[prop]["SELECT-TYPE-DECLARACAO"]);
        }
      }
    }
  }, []);

  return (
    <>
      {TipoPortal === 3 ? <NavMenu /> : <NavMenuCM />}
      <div className="section-title rotes_pages">
        <h6>{route}</h6>
      </div>
      <div className="section-title-portarias">
        <h4>{SacopFilter}</h4>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h4
          style={{
            color: "#0c4b36",
          }}
        >
          Convênios
        </h4>
        <br />
        {/* <a className="btn_box" target="_blank" href="#">
          <img
            className="svg-button-transparencia"
            src="/icons/RECEBIDAS.svg"
            alt="RECEBIDAS"
          />
          <p>Transferências Voluntárias</p>
        </a> */}
        <br />
        <br />
        <h4
          style={{
            color: "#0c4b36",
          }}
        >
          Declarações
        </h4>
      </div>

      {nameTypeDeclaracao && (
        <ListAndFiltersType
          type={"Convênio"}
          subType={"Declarações - Recebidas"}
        />
      )}

      {nameTypeDeclaracao && (
        <ListAndFiltersType type={"Convênio"} subType={"Contrato de Repasse"} />
      )}

      {nameTypeDeclaracao && (
        <ListAndFiltersType type={"Convênio"} subType={"Convênios"} />
      )}

      {nameTypeDeclaracao && (
        <ListAndFiltersType type={"Convênio"} subType={"Relação Recebidas"} />
      )}

      <Footer />
    </>
  );
};

export default ListCardSacopAndFilters;
