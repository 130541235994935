import NavMenu from "../../../../components/public/others/Navbar";
import NavMenuCM from "../../../../components/public/others/NavbarCM";
import Footer from "../../../../components/public/others/Footer";
import { TipoPortal } from "../../../../services/variables";
import MenuSalaEmpreendedor from "../../../../components/public/transparency/salaEmpreendedor/MenuEmpreendedor";
import SubTitle from "../../../../components/public/others/title/SubTitle";

export default function meiSalaEmpreendedor(props) {
  const route = props.route;

  return (
    <>
      {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
      {TipoPortal === 3 ? (
        <>
          <NavMenu />
        </>
      ) : (
        <>
          <NavMenuCM />
        </>
      )}
      <div className="section-title rotes_pages">
        <h6>Início {route}</h6>
      </div>
      <div className="section-title-portarias">
        <h4>Sala Empreendedor</h4>
      </div>
      <MenuSalaEmpreendedor filter={"MEI"} />
      <SubTitle subTitle={"MEI"} border={true} />
      <div className="section-title">
        <p>
          MEI significa Microeemprendedor Individual, ou seja, um profissional
          autônomo. Quando você se cadastra como um, você passa a ter CNPJ, ou
          seja, tem facilidades com a abertura de conta bancária, no pedido de
          empréstimos e na emissão de notas fiscais, além de ter obrigações e
          direitos de uma pessoa jurídica. Confira abaixo tudo o que você
          precisa saber para se tornar um MEI. Para ser registrado como
          Microempreendedor Individual, a área de atuação do profissional
          precisa estar na lista oficial da categoria, já que o MEI foi criado
          com o objetivo de regularizar a situação de profissionais informais.
        </p>
      </div>
      <br />
      <Footer />
    </>
  );
}
